<template>
    <!-- Navigation Drawer - User Panel -->
    <v-navigation-drawer app :mini-variant="mini" :permanent="drawer">
        <!-- Navigation Drawer Header -->
        <div class="avsb-drawer-header-logo-container">
            <router-link :to="{ name: 'Home' }">
                <v-img
                    class="mx-auto mt-2"
                    max-height="48"
                    max-width="180"
                    src="@/assets/images/opt_avsb_logo_with_text.svg"
                    v-if="!mini"
                />
                <v-img
                    class="mx-auto mt-2"
                    min-height="40"
                    max-height="50"
                    max-width="35"
                    src="@/assets/images/opt_avsb_logo_no_text.svg"
                    v-if="mini"
                />
            </router-link>
        </div>

        <!-- Navigation Drawer Body -->
        <v-list dense>
            <template v-for="(app, idx) in apps">
                <v-list-item :key="idx">
                    <v-list-item-icon>
                        <v-icon>
                            {{ app.icon }}
                        </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ app.name }}
                    </v-list-item-title>
                </v-list-item>

                <template v-for="item in app.items">
                    <v-list-item
                        :key="item.name"
                        link
                        :to="{ name: item.route }"
                    >
                        <v-list-item-icon>
                            <v-icon>
                                {{ item.icon }}
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            {{ item.name }}
                        </v-list-item-title>
                    </v-list-item>
                </template>
            </template>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
    components: {},
    computed: {
        drawer: {
            get() {
                return this.$store.state.drawer;
            },
            set(val) {
                this.$store.commit("TOGGLE_DRAWER", val);
            },
        },
        mini() {
            return this.$vuetify.breakpoint.xs;
        },
    },
    data: () => ({
        dialog: {
            createItem: false,
        },
        links: [
            { icon: "mdi-home", title: "Dashboard", route: "Home" },
            { icon: "mdi-plus-circle", title: "Emails", route: "Emails" },
        ],
        apps: [
            {
                // icon: "mdi-application",
                name: "Application",
                items: [
                    {
                        icon: "mdi-view-dashboard",
                        name: "Dashboard",
                        route: "Home",
                    },
                    {
                        icon: "mdi-calendar",
                        name: "Calendar",
                        route: "Calendar",
                    },
                ],
            },
            {
                // icon: "mdi-chart-donut",
                name: "Reports",
                items: [
                    { icon: "mdi-chart-box", name: "Reports", route: "Report" },
                ],
            },
        ],
    }),
    name: "NavUser",
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>

<style scoped>
ul {
    padding-left: 0;
}
li {
    list-style: none;
}

.header-logo {
    color: black;
    text-decoration: none;
}
.footer-note {
    font-size: 13px;
}
</style>


// #2a3042 - Navigation bar background color
// #6a7187 - Navigation bar link/icon color
// #f8f8fb - Main views background color
// #eff2f7 - Table header background color
// #495057 - Default text color